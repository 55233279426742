%body {
	font: {
		family: $font-text;
		size: 19px;
		weight: normal;
	};
    font-color:    #232323;
	line-height:    1.45;

	h1, h2, h3, h4, h5, h6 {
        font-family: $font-title;
        line-height: 1.2;
	}
    h1 {
        font-size:    2.368em;
        font-weight:    bold;
    }
    h2 {
        font-size:    1.842em;
        font-weight:    bold;
    }
    h3 {
        font-size:    1.316em;
        font-weight:    bold;
        &.alt {
            color:$theme-primary-color;
        }
    }
    h4 {
        font-size:    1.316em;
        font-weight:    500;
    }
    h5 {
        font-size:    0.947em;
        font-weight:    bold;
    }
    h6 {
    }
	p {

	}

	ul, ol {

	}

	a {
		color: $theme-primary-color;
		text-decoration: none;
	}

	#{$all-buttons} {
		@include button;
	}

	em {

	}

	strong {

	}

	blockquote {
		cite {
		}
	}
}
