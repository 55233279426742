@import url("//cdnjs.cloudflare.com/ajax/libs/font-awesome/4.6.3/css/font-awesome.min.css");

$theme-primary-color: #6aab35;
$theme-secondary-color: #654122;

$font-title: 'Bitter', arial, serif;
$font-text: "proxima-nova",arial, sans-serif;

$footer-background-color: #232323;
$footer-header-color: #fff;
$footer-font-color: #959595;

$yellow-margin:     5.263rem;
$orange-margin:     4.211rem;
$purple-margin:   3.158rem;
$dark-margin:       2.632rem;
$blue-margin:       2.368rem;
$green-margin:      2.105rem;
$darkorange-margin: 1.579rem;
$pink-margin:       1.316rem;
$lightblue:         1.053rem;
$darkpink:          0.789rem;
// $euphoria-column-gutter: 1em;
// $euphoria-column-gutter-fallback: 1em;
