@import "euphoria/all";
@import "partials/base";
@import "partials/typography";
@import "partials/form";
@import "owl-carousel/owl.carousel";

// Activeer bij gebruik fancybox
$fancybox-image-url: '../images/fancybox' !default;
@import "../../node_modules/fancybox/dist/scss/jquery.fancybox";

@include normalize;

body {
    @extend %body;
    box-sizing: border-box;
    background: #fff;
}

.columns {
    &.two {
        @include basic-two-columns;
    }

    &.three {
        @include basic-columns(
            $count: 3,
            $dimensions: (30%, 40%, 30%),
            $breakpoint: phone-landscape
        );
    }
}

button, .button {
    position: relative;
    background: $theme-primary-color !important;
    color: #fff !important;
    border-radius: 2px !important;
    text-shadow: none !important;
    border: 2px solid $theme-primary-color !important;
    transition: background .2s color .2s;
    z-index: 1 !important;
    &:hover {
        background: #fff !important;
        color: $theme-primary-color !important;
    }
    &.active {
        background: #232323 !important;
        color: #fff !important;
        border-color: #232323 !important;
    }
    &.alt {
        color: $theme-primary-color !important;
        border: 2px solid $theme-primary-color;
        background: transparent !important;
        &:before {
            content: "";
            background: #fff;
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            z-index: -1;
            height: 100%;
            transition: width .2s;
        }
        &:hover {
            &:before {
                width: 0%;
            }
        }
    }
}

#google_map img {
    max-width: none !important;
}

.content {
    box-sizing: border-box;
    max-width: calc(1266px + 2em);
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1em;
    padding-left: 1em;

    //@media only screen and ( max-width: 769px ) {
    //    margin:0 1em;
    //}
    &.medium {
        max-width: 1050px;
    }
    &.smaller {
        max-width: 900px;
    }
}

.fg-element {
    min-height: 18px;
}

.breadcrumb {
    font-size: 0.842em;
    color: #696969;
    span {
        color: #242424;
        a {
            color: #242424;
            text-decoration: underline;
            &:hover {
                color: $theme-primary-color;
            }
        }
    }
}

header.header {
    position: fixed;
    z-index: 70;
    box-sizing: border-box;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    @media only screen and (max-width: 600px) {
        font-size: 16px;
    }

    #topbar, #head {
        @include clearfix;
        position: relative;
        box-sizing: border-box;
    }

    #topbar {
        color: #fff;
        background: url('../images/topbarbg.jpg');
        .topbar-left {
            float: left;
            font-size: 15px;
            @include tablet-portrait {
                width: 100%;
                text-align: center;
            }
            p {
                margin: $darkpink $darkpink $darkpink 0;
            }
            a {
                color: #fff;
            }
        }
        .menu {
            position: relative;
            float: right;
            font-size: 16px;
            @include tablet-portrait {
                display: none;
            }
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                > li {
                    display: inline-block;
                    a {
                        display: inline-block;
                        color: #fff;
                        padding: $darkpink $darkpink $darkpink 0;
                        &[href="/waarom-teakhout"] {
                            &:after {
                                display: inline-block;
                                vertical-align: baseline;
                                content: "\f105";
                                font: normal normal normal .9em/1 FontAwesome;
                                margin-left: .3em;
                            }
                        }
                    }
                    &.active, &:hover {
                        a {
                            color: $theme-primary-color;
                        }
                    }
                }
            }
            .submenu {
                position: absolute;
                left: 35%;
                display: none;
                z-index: 99;
                ul {
                    li {
                        display: block;
                        border-bottom: 1px solid #d6d6d6;
                        padding: 0;
                        a {
                            display: block;
                            color: #242424;
                            background: #f8f8f8;
                            text-decoration: none;
                            font-weight: 500;
                            font-size: .9em;
                            padding: .5em 1em;
                            border-left: 1px solid #d6d6d6;
                            border-right: 1px solid #d6d6d6;
                        }
                        &.active, &:hover {
                            a {
                                color: $theme-primary-color;
                            }
                        }
                    }
                }

            }
        }
    }
    #head {
        background-color: #fff;
        border-bottom: 1px solid #d6d6d6;
        .logo {
            float: left;
            margin-right: $orange-margin;

            @include phone-portrait {
                margin-left: -1em;
                margin-right: 0;
            }
            img {
                display: block;
                @media only screen and (max-width: 600px) {
                    height: 4em;
                }
            }
        }
        .site-menu {
            @include tablet-portrait {
                display: none;

            }
            ul {
                display: inline-block;
                list-style: none;
                margin: 0;
                padding: 0;
                > li {
                    margin-top: -.1em;
                }
                li {
                    display: inline-block;
                    margin-left: -.18em;
                    &.active {
                        a {
                            color: $theme-primary-color;
                        }
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                    a {
                        color: #222222;
                        font-size: 0.789em;
                        font-weight: bold;
                        text-transform: uppercase;
                        padding: $blue-margin $blue-margin $blue-margin 0;
                        display: inline-block;
                        &.expand {
                            display: none;
                        }
                    }
                    &:hover {
                        cursor: pointer;
                        a {
                            color: $theme-primary-color;
                        }
                    }
                    /*if the child has children */
                    &.has-children {
                        position: relative;
                        z-index: 9999;
                        a {
                            &:after {
                                display: inline-block;
                                vertical-align: text-bottom;
                                content: "\f105";
                                font: normal normal normal .9em/1 FontAwesome;
                                margin-left: .3em;
                                font-size: 1.2em;
                            }
                        }
                        > ul {
                            position: absolute;
                            text-align: left;
                            display: none;
                            //padding-top:2.368rem;
                            padding-top: 0.34rem;
                            li {
                                padding: 0;
                                display: block;
                                margin: 0;
                                a {
                                    position: relative;
                                    display: block;
                                    background: #f8f8f8;
                                    text-decoration: none;
                                    padding: .5rem $lightblue;
                                    border-left: 1px solid #d6d6d6;
                                    border-bottom: 1px solid #d6d6d6;
                                    border-right: 1px solid #d6d6d6;
                                    &:after {
                                        display: none;
                                    }
                                }
                                &.active {
                                    a {
                                        color: $theme-primary-color;
                                    }
                                }
                                &:first-child {
                                    a {
                                        padding-top: 1em;
                                    }
                                }
                                &:last-child {
                                    a {
                                        //&:after {
                                        //    display:none;
                                        //}
                                    }
                                }
                            }
                        }
                        &:hover {
                            position: relative;
                            //background:#eee;
                            > ul {
                                display: block;
                                width: 150%;
                                a {
                                    color: #484848;
                                    &:hover {
                                        color: $theme-primary-color;
                                    }
                                }
                            }
                        }
                        &.page-6976a7bf-1697-11e6-8ac1-005056994792 {
                            &:hover > ul {
                                width: 270%;
                            }
                        }
                    }
                    /* LI last child */
                    &:last-child {
                        a {
                            padding-right: 0;
                        }
                    }
                }
            }
        }
    }
}

section {
    /***********************************************************/
    // Content
    /***********************************************************/
    &#header {
        position: relative;
        overflow: hidden;
        p {
            margin: 0;
        }
        .headerBackground {
            position: relative;
            width: 100%;
            height: 100%;

            .mod-text {
                width: 100%;
                height: 500px;

                .map-wrap {
                    position: relative;
                }
                .overlay {
                    width: 100%;
                    min-height: 500px;
                    position: absolute;
                    top: 0;
                }
            }
            .element-template--headerimage-large {
                position: relative;
                width: 100%;
                height: 60vh;
                margin-bottom: 0;
                @media only screen and (max-width: 600px) {
                    height: 65vh
                }
                .image {
                    background-size: cover;
                    height: 100%;
                    background-position: center;
                }
            }
            .element-template--headerimage-medium {
                position: relative;
                width: 100%;
                height: 40vh;
                margin-bottom: 0;
                border-bottom: 2px solid $theme-primary-color;
                .image {
                    background-size: cover;
                    height: 100%;
                    background-position: center;
                }
            }
            .element-template--headerimage-small {
                position: relative;
                width: 100%;
                height: 25vh;
                margin-bottom: 0;
                border-bottom: 2px solid $theme-primary-color;
                .image {
                    background-size: cover;
                    height: 100%;
                    background-position: center;
                }
            }
        }
        .headerText {
            position: absolute;
            box-sizing: border-box;
            max-width: calc(1266px + 4.444em);
            width: 27%;
            @media only screen and (max-width: 1280px) {
                width: 45%;
            }
            margin-left: calc((100% - 1266px) / 2);
            @media only screen and (max-width: 769px) {
                margin-left: 0;
            }
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            -webkit-font-smoothing: antialiased;
            text-shadow: 0 0 1px rgba(5, 54, 77, .45);
            padding: $blue-margin;
            background: rgba(255, 255, 255, .9);
            border-left: 5px solid $theme-primary-color;
            border-radius: 0px 2px 2px 0px;
            @include phone-portrait {
                position: absolute;
                width: 100%;
                height: 100%;
                border-left: 0px;
                background: rgba(255, 255, 255, .5);
                margin: 0 auto;
                transform: none;
                top: 0;
                text-align: center;
            }
            h2, p {
                margin: 0;
            }
            h2 {
                margin-bottom: $darkorange-margin;
            }
            p {
                &:first-of-type {
                    margin-bottom: $blue-margin;
                }
            }
        }
    }
    &#fourQuotes {
        font-size: 0.895em;
        border-bottom: 1px solid #e4e4e4;
        .content {
            ul {
                display: table;
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    position: relative;
                    box-sizing: border-box;
                    display: table-cell;
                    width: 25%;
                    padding: $lightblue 0 $lightblue $blue-margin;
                    vertical-align: middle;
                    @include phone-portrait {
                        display: block;
                        padding: 1.053rem 1.053rem 1.053rem 3.5rem !important;
                        width: 100%;
                        &:before {
                            left: .75em !important;
                        }
                    }
                    p {
                        margin: 0;
                    }
                }
                &.checklist {
                    li {
                        &:first-of-type {
                            padding-left: 0;
                            &:before {
                                left: -1.25em;
                            }
                        }
                        &:before {
                            position: absolute;
                            top: 50%;
                            left: .75em;
                            transform: translateY(-50%);
                            content: "\f00c";
                            font: normal normal normal 1em/1 FontAwesome;
                            color: $theme-primary-color;
                        }
                    }
                }
                &.webshop {
                    li {
                        &:first-child {
                            &:before {
                                content: "";
                                background: {
                                    image: url('../images/icons/car.png');
                                    repeat: no-repeat;
                                    size: 55%;
                                    position: center;
                                }
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translate(-40%, -50%);
                            }
                        }
                        &:nth-child(2) {
                            &:before {
                                content: "";
                                background: {
                                    image: url('../images/icons/mouse.png');
                                    repeat: no-repeat;
                                    size: 55%;
                                    position: center;
                                }
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: 1em;
                                top: 50%;
                                transform: translate(-40%, -50%);
                            }
                        }
                        &:nth-child(3) {
                            &:before {
                                content: "";
                                background: {
                                    image: url('../images/icons/map.png');
                                    repeat: no-repeat;
                                    size: 55%;
                                    position: center;
                                }
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: .8em;
                                top: 50%;
                                transform: translate(-40%, -50%);
                            }
                        }
                        &:nth-child(4) {
                            &:before {
                                content: "";
                                background: {
                                    image: url('../images/icons/balloons.png');
                                    repeat: no-repeat;
                                    size: 55%;
                                    position: center;
                                }
                                width: 50px;
                                height: 50px;
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translate(-40%, -50%);
                            }
                        }
                    }
                }
            }
        }
    }
    &#frame {
        position: relative;
        padding: $purple-margin 0;
        background: url('../images/whitebg.jpg');
        &.greybg {
            background: url('../images/greybg.jpg');
        }
        &.whitebg {
            background: url('../images/whitebg.jpg');
        }
        &.nopadding {
            padding: 0;
        }
        &.greyBorderTop {
            &:before {
                background: url('../images/greytopborder.png');
                content: "";
                width: 100%;
                height: 25px;
                top: -1em;
                position: absolute;
            }
        }
        &.whitebordertop {
            &:before {
                background: url('../images/whitebordertop.png');
                content: "";
                width: 100%;
                height: 25px;
                top: -1em;
                position: absolute;
            }
        }
        .topTitle {
            position: relative;
            &:before {
                height: 1px;
                width: 70%;
                background: #dfdfdf;
                content: "";
                position: absolute;
                //bottom: -0.47em;
                margin-top: 1.44rem;
                left: 50%;
                transform: translateX(-50%);
                top: 100%;
                z-index: 0;
            }
            h4, h3 {
                position: relative;
                display: table;
                margin: 0 auto;
                margin-bottom: calc(1.316rem + 2.368rem); //Pink margin + Blue Margin
                &:before {
                    position: absolute;
                    content: "";
                    height: 5px;
                    left: 50%;
                    width: 90%;
                    top: calc(100%);
                    margin-top: $pink-margin;
                    background: #6aab35;
                    transform: translateX(-50%);
                }
            }
        }
        .columns {
            &.twoo {
                display: table;
                margin-top: -3em;
                @media only screen and (max-width: 769px) {
                    margin-top: 0em;
                }
                @media only screen and (max-width: 600px) {
                    display: block;
                }
                &.first {
                    margin-top: 0;
                }
                .column {
                    display: table-cell;
                    width: 50%;
                    vertical-align: middle;
                    @media only screen and (max-width: 600px) {
                        display: block;
                        width: 100%;
                        @include clearfix;
                    }
                    @include phone-portrait {
                        border: 0;
                    }
                    .mod-images {
                        margin-bottom: 0;
                    }
                    &:first-child {
                        padding-right: $dark-margin;
                        @media only screen and (max-width: 600px) {
                            padding: 0;
                        }
                    }
                    &:last-child {
                        padding-left: $dark-margin;
                        @media only screen and (max-width: 600px) {
                            padding: 0;
                        }
                    }
                    h3, h4 {
                        color: $theme-secondary-color;
                    }
                }
            }
            &.sidebar {
                @include basic-columns(
                    $count: 2,
                    $dimensions: (30%, 70%),
                    $gutter: (3em, 15%),
                    $breakpoint: phone-landscape
                );
                .column {
                    &:first-child {
                        h4 {
                            margin: 0 0 1em;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "";
                                height: 5px;
                                left: 0%;
                                width: 30%;
                                top: calc(80%);
                                margin-top: $pink-margin;
                                background: #6aab35;
                            }
                        }
                        ul {
                            list-style: none;
                            margin: 0;
                            padding: 0;
                            li {
                                display: block;
                                a {
                                    display: block;
                                    border-bottom: 1px solid #ededed;
                                    padding: .5em 0;
                                    cursor: pointer;
                                    &:hover {
                                        background: #eee;
                                        cursor: pointer;
                                    }
                                }
                                &.active {
                                    a {
                                        background: #eee;
                                    }
                                }
                            }
                        }
                    }
                    .topTitle {
                        margin-bottom: 3em;
                        h4 {
                            margin: 0;
                        }
                    }
                }
            }
        }
        &.greenCTA {
            position: Relative;
            box-sizing: border-box;
            overflow: hidden;
            padding: 0;
            .content {
                position: relative;
                display: table;
                .image {
                    display: table-cell;
                    padding-right: $darkorange-margin;
                    @media only screen and (max-width: 769px) {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                }
                .text {
                    display: table-cell;
                    vertical-align: middle;
                    color: #fff;
                    @media only screen and (max-width: 769px) {
                        display: block;
                        width: 100%;
                        text-align: center;
                    }
                    h2 {
                        margin: 0;
                        margin-bottom: $darkpink;
                    }
                    h4 {
                        margin: 0;
                    }
                }
            }
            .ctabg {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                &:after {
                    background: rgba($theme-primary-color, .8);
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    content: "";
                    pointer-events: none;
                }
                .mod-images {
                    margin: 0;
                    display: block;
                    height: 100%;
                    ul {
                        list-style: none;
                        margin: 0;
                        padding: 0;
                        display: block;
                        height: 100%;
                        li {
                            display: block;
                            height: 100%;
                            img {
                                object-fit: cover;
                                width: 100%;
                                @media only screen and (max-width: 769px) {

                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &#bgFrame {
        position: relative;
        background: url('../images/framebg.jpg');
        background-size: cover;
        padding: $yellow-margin 0;
        color: #fff;
        overflow: hidden;
        text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.65);
        @include tablet-portrait {
            padding: $yellow-margin 1em;
            text-align: center;
        }
        .content {
            .topTitle {
                h2 {
                    margin: 0 0 $yellow-margin 0;
                }
            }
            .bottomTitle {
                margin-top: $purple-margin;
                p {
                    margin: 0;
                }
            }
            .columns {
                &.two {
                    @include basic-columns(
                        $count: 2,
                        $dimensions: (50%, 50%),
                        $gutter: (125px, 15%),
                        $breakpoint: phone-landscape
                    );
                    .column {
                        ul {
                            li {
                                -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
                                -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
                                box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.75);
                            }
                        }
                    }
                    &:nth-child(odd) {
                        .mod-images {
                            margin-top: -10em;
                            @media only screen and (max-width: 769px) {
                                margin-top: 0;
                            }
                        }
                    }
                    &:last-child {
                        .mod-images {
                            margin-bottom: 0 !important;
                        }
                    }
                }
            }
        }
    }
    &#contact {
        padding: $purple-margin 0;
        background: url('../images/whitebg.jpg');
        .content {
            @include basic-columns(
                $selector: '.column',
                $count: 2,
                $dimensions: (75%, 25%),
                $gutter: ($green-margin, 10%),
                $breakpoint: phone-landscape
            );
            .column {
                &:first-child {
                    padding: $purple-margin;
                    border: 1px solid #ededed;
                    border-radius: 2px;
                    background: #fff;

                    @include phone-landscape {
                        padding: $purple-margin/2;
                    }
                    h2 {
                        color: $theme-primary-color;
                    }
                    button {
                        float: right;
                    }
                    input, textarea {
                        &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
                            color: #232323;
                        }
                        &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
                            color: #232323;
                            opacity: 1;
                        }
                        &::-moz-placeholder { /* Mozilla Firefox 19+ */
                            color: #232323;
                            opacity: 1;
                        }
                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: #232323;
                        }
                    }
                    select {
                        color: #232323;
                    }

                }
                &:last-child {
                    padding: $green-margin;
                    border: 1px solid #ededed;
                    border-radius: 2px;
                    background: #fff;
                    span {
                        display: block;
                        padding: $darkorange-margin 0 0 0;
                        border-bottom: 1px solid #ededed;
                        &:last-of-type {
                            border-bottom: 0;
                        }
                        h6 {
                            font-size: 1em;
                            color: $theme-primary-color;
                            margin: 0;
                        }
                        p {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
    /***********************************************************/
    // Einde Content
    /***********************************************************/
    &#footer {
        .footer_top {
            background: #232323;
            color: #959595;
            border-top: 5px solid $theme-primary-color;
            padding: $dark-margin 0;
            @include tablet-portrait {
                padding: $dark-margin 1em;
                text-align: center;
            }
            .content {
                @include basic-columns(
                    $selector: '.column',
                    $count: 3,
                    $dimensions: (28%, 52%, 20%),
                    $breakpoint: tablet-portrait,
                    $gutter: (4.211em, 10%),
                    $gutter-bottom: 4.211em
                );
                h5 {
                    font-size: 1em;
                    font-weight: bold;
                    margin: 0;
                    color: #fff;
                }
                h6 {
                    font-size: 0.842em;
                    font-weight: bold;
                    color: #fff;
                    margin: $green-margin 0 $lightblue 0;
                }
                p {
                    font-size: 0.895em;
                }
                .column {
                    &:nth-child(2) {
                        .footer_adress {
                            display: table;
                            width: 100%;
                            ul {
                                display: table-cell;
                                width: 33.3333333%;
                                padding: 0;
                                margin: 0;
                                list-style: none;
                                @include tablet-portrait {
                                    display: block;
                                    width: 100%;
                                }
                                li {
                                    font-size: 0.895em;
                                }
                            }
                        }
                        .columns.two {
                            @include basic-columns(
                                $count: 2,
                                $dimensions: (55%, 45%),
                                $breakpoint: tablet-portrait,
                                $gutter: (4.211em, 10%)
                            );
                            margin-top: 1.5em;
                            .button {
                                font-size: 0.895em;
                            }
                        }
                    }
                    &:last-of-type {
                        table {
                            @include tablet-portrait {
                                margin: 0 auto;
                            }
                            td {
                                padding: 0 .2em;
                            }
                        }
                    }
                }
            }
        }
        .footer_bottom {
            padding: $pink-margin 0;
            font-size: 0.895em;
            color: #232323;
            p {
                margin: 0;
            }
            .content {
                .left {
                    display: inline-block;
                    vertical-align: middle;
                    .copyright {
                        display: inline-block;
                        margin-right: -1em;
                    }
                    .footer-menu {
                        display: inline-block;
                        ul {
                            @include clear-ul;
                            list-style: none;
                            margin-top: 0;
                            margin-bottom: 0;
                            margin-left: 1em;
                            padding: 0;
                            li {
                                display: inline-block;
                                &:before {
                                    content: "|";
                                    padding: 0 0.3em;
                                }
                                a {
                                    text-transform: none;
                                    text-decoration: underline;
                                    color: #232323;
                                    &:hover {
                                        color: $theme-primary-color;
                                    }
                                }
                            }
                        }
                    }
                }
                .right {
                    display: inline-block;
                    float: right;
                    img {
                        vertical-align: middle;
                    }
                }
                @include tablet-portrait {
                    .left {
                        display: block;
                        text-align: center;
                        .copyiright {
                            display: block;
                        }
                        .footer-menu {
                            display: block;
                            ul {
                                li {
                                    &:last-child {
                                        &:after {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .right {
                        display: block;
                        float: none;
                        text-align: center;
                        margin-top: 1em;
                    }
                }
            }
        }
    }
}

/***********************************************************/
// Image modules
/***********************************************************/
.mod-images {
    margin-bottom: $yellow-margin;
    //overflow:hidden;
    &.element-template--bigsmall {
        ul {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
            .pinterest {
                bottom: initial;
                left: 1em;
                right: initial;
                top: 1em;
            }
            li {
                width: 50%;
                box-sizing: border-box;
                border: 15px solid #fff;
                overflow: hidden;
                max-height: 400px;
                img {
                    object-fit: cover;
                    width: 100%;
                    display: block;
                }
                &:not(:first-of-type) {
                    position: relative;
                    float: right;
                    margin-top: -25%;
                    margin-right: -2em;
                }
                &:first-of-type {
                    width: 100%;
                    img {
                        display: block;
                        object-fit: cover;
                        width: 100%;
                    }
                }

            }
        }
    }
    &.element-template--sixtysixty {
        position: relative;
        .pinterest {
            top: 1em;
            right: initial;
            left: 1em;
            bottom: initial;
        }
        ul {
            position: relative;
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                width: 80%;
                box-sizing: border-box;
                border: 15px solid #fff;
                overflow: hidden;
                max-height: 400px;
                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                }
                &:last-child {
                    margin-right: -0%;
                    margin-top: -20%;
                    position: relative;
                    float: right;
                }
            }
        }
    }
    &.element-template--bricks {
        .grid {
            position: relative;
            box-sizing: border-box;
            max-width: 100%;
            height: auto;
            display: block;
            .grid-item {
                opacity: 1;
                box-sizing: border-box;
                display: block;
                width: 24.408%;
                max-width: 309px;
                height: auto;
                margin: 0;
                padding: 0;

                a {
                    display: block;
                }

                @include tablet-portrait {
                    width: calc((100% - 20px) / 3);
                }

                @include breakpoint(iplusP) {
                    width: calc((100% - 10px) / 2);
                }

                @media screen and (max-width: 375px) {
                    width: 100%;
                    margin-right: auto;
                    margin-left: auto;
                    margin-bottom: 1em;

                }

                img {
                    width: 100%;
                    display: block;
                }

                &:before, &:after {
                    position: absolute;
                    opacity: 0;
                    transition: opacity .2s;
                }

                &:before {
                    background: #000;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    content: "";
                    pointer-events: none;
                }

                &:after {
                    content: "+";
                    left: 50%;
                    top: 50%;
                    font-size: 2.4em;
                    font-weight: 400;
                    transform: translateX(-50%) translateY(-50%);
                    color: #fff;
                    pointer-events: none;
                }

                &:hover {
                    &:before {
                        opacity: .6;
                    }
                    &:after {
                        opacity: 1;
                    }
                    .various {
                        opacity: 1;
                    }
                }
                .various {
                    position: absolute;
                    z-index: 99;
                    bottom: 1em;
                    left: 1em;
                    border: 2px solid #fff;
                    box-sizing: border-box;
                    padding: .2em .5em;
                    color: #fff;
                    font-size: .8em;
                    cursor: pointer;
                    opacity: 0;
                    transition: opacity .2s ease-in-out;
                    &:hover {
                        background: #fff;
                        color: #000;
                    }
                }
            }

            &.loading {
                figure {
                    opacity: 0;
                }
            }
        }
    }
    &.element-template--voorbeeld {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            li {
                width: 50%;
                display: inline-block;
                overflow: hidden !important;
                vertical-align: center;
                box-sizing: border-box;
                border: 15px solid #fff;
                -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                position: relative;
                z-index: 1;
                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
                &:after {
                    background: $theme-primary-color;
                    color: #fff;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    padding: .5em;
                    font-weight: 500;
                }
                a {
                    display: block;
                    height: 100%;
                    background: #000;
                    &:before {
                        background: #000;
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 100%;
                        width: 100%;
                        content: "";
                        pointer-events: none;
                        opacity: 0;
                        transition: opacity .2s;
                        z-index: 1;
                    }
                    &:after {
                        position: absolute;
                        content: "+";
                        left: 50%;
                        top: 50%;
                        font-size: 2em;
                        font-weight: 300;
                        transform: translateX(-50%) translateY(-50%);
                        color: #fff;
                        pointer-events: none;
                        opacity: 0;
                        transition: opacity .2s;
                        z-index: 2;
                    }
                    &:hover {
                        &:before {
                            opacity: .6;
                        }
                        &:after {
                            opacity: 1;
                        }
                    }
                }
                &:first-child {
                    &:after {
                        content: "Schets";
                    }
                }
                &:nth-child(2) {
                    top: 1em;
                    left: -2em;
                    @media only screen and (max-width: 600px) {
                        left: 0;
                    }
                    &:after {
                        content: "Resultaat";
                    }
                }
                img {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

/***********************************************************/
// Einde Image modules
/***********************************************************/

/***********************************************************/
// Link modules
/***********************************************************/
.mod-links {
    &.element-template--medewerkers {
        @include basic-columns(
            $selector: '.medewerker',
            $count: 4,
            $dimensions: (25%, 25%, 25%, 25%),
            $breakpoint: tablet-portrait,
            $gutter: ($darkorange-margin, 10%),
            $gutter-bottom: $dark-margin
        );
        .medewerker {
            position: relative;
            background: url('../images/smallblockbg.png');
            border: 1px solid #cccccc;
            padding: $darkpink $darkpink $darkorange-margin $darkpink;
            -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
            -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
            .image {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                margin-bottom: $darkorange-margin;
                height: 272px;
                overflow: hidden;
                img {
                    display: block;
                    object-fit: cover;
                    width: 100%;
                }
            }
            .naam, .functie {
                position: relative;
                width: 100%;
                box-sizing: border-box;
                text-align: center;
            }
            .naam {
                font-family: $font-title;
                font-weight: 600;
            }
            .functie {
                color: $theme-secondary-color;
                font-weight: 500;
                min-height: 53px;
            }
        }
    }
    &.element-template--3imagesbuttons {
        @include basic-columns(
            $selector: '.item',
            $count: 3,
            $dimensions: (33.33333%, 33.33333%, 33.33333%),
            $breakpoint: tablet-portrait,
            $gutter: ($darkorange-margin, 10%),
            $gutter-bottom: $dark-margin
        );
        .item {
            position: relative;
            .image {
                width: 100%;
                height: 250px;
                display: block;
                overflow: hidden;
                img {
                    object-fit: cover;
                    width: 100%;
                    transform: scale(1, 1);
                    transition: transform .5s;
                }
            }
            &:hover {
                .image {
                    img {
                        transform: scale(1.1, 1.1);
                    }
                }
            }
            .text {
                position: absolute;
                padding: 60px;
                box-sizing: border-box;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                color: #333;
                @include phone-small-portrait {
                    padding: 0;
                }
                h4 {
                    line-height: 1.5;
                }
                span {
                    background: #fff;
                }
            }
        }
    }
}

/***********************************************************/
// Einde Link modules
/***********************************************************/

/***********************************************************/
// Detail modules
/***********************************************************/

.mod-detail {
    &.element-template--blog {
        .overview {
            .items {
                @include basic-columns(
                    $selector: '.item',
                    $count: 2,
                    $dimensions: (50%, 50%),
                    $breakpoint: tablet-portrait,
                    $gutter: ($purple-margin, 10%),
                    $gutter-bottom: $blue-margin
                );
                .image {
                    position: relative;
                    display: block;
                    max-height: 300px;
                    overflow: hidden;
                    border-bottom: 4px solid $theme-primary-color;
                    a {
                        display: block;
                        img {
                            display: block;
                            object-fit: cover;
                            width: 100%;
                            max-height: 15.789em;
                        }
                    }
                }
                .text {
                    background: #fff;
                    padding: $green-margin;
                    border-left: 1px solid #e4e4e4;
                    border-right: 1px solid #e4e4e4;
                    border-bottom: 1px solid #e4e4e4;
                    h3 {
                        margin-top: 0;
                        margin-bottom: $lightblue;
                    }
                    .date {
                        font-weight: 600;
                        margin-bottom: $darkorange-margin;
                    }
                    p {
                        font-weight: 500;
                        &:first-of-type {
                            min-height: 100px;
                        }
                        &:last-of-type {
                            margin-bottom: 0;
                        }
                    }
                    .button {
                        margin-top: $blue-margin;
                    }
                }
            }
            .pagination {
                .previous, .next {
                    display: none;
                }
                .page-links {
                    display: table;
                    margin: 20px auto 0 auto;

                    span {
                        a {
                            min-width: 20px;
                            padding: .5em .6em;
                            background: $theme-primary-color;
                            display: inline-block;
                            color: #fff;
                            text-align: center;
                            margin-right: 0.526em;
                            border-radius: 5px;
                            &:hover, &.active {
                                background: $theme-primary-color;
                            }
                        }
                        &:last-child {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .pagination {
                .previous, .next {
                    display: none;
                }
                .page-links {
                    display: table;
                    margin: $green-margin auto 0 auto;

                    span {
                        a {
                            min-width: 20px;
                            padding: .5em .6em;
                            background: $theme-primary-color;
                            display: inline-block;
                            color: #fff;
                            text-align: center;
                            margin-right: 0.526em;
                            border-radius: 5px;
                            &:hover, &.active {
                                background: #232323;
                            }
                        }
                        &:last-child {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    &.element-template--onderhoud {
        .overview {
            .items {
                @include basic-columns(
                    $selector: '.item',
                    $count: 4,
                    $dimensions: (25%, 25%, 25%, 25%),
                    $breakpoint: tablet-portrait,
                    $gutter: ($purple-margin, 10%),
                    $gutter-bottom: $dark-margin
                );
                .item {
                    &:hover {
                        a {
                            color: $theme-primary-color;
                        }
                    }
                }
                .image {
                    display: block;
                    max-height: 200px;
                    overflow: hidden;
                    border-bottom: 4px solid $theme-primary-color;
                    a {
                        display: block;
                    }
                    img {
                        display: block;
                        object-fit: cover;
                        height: 100%;
                        max-height: 15.789em;
                    }
                }
                .text {
                    background: #fff;
                    padding: $green-margin;
                    border-left: 1px solid #e4e4e4;
                    border-right: 1px solid #e4e4e4;
                    border-bottom: 1px solid #e4e4e4;
                    p, a {
                        color: #232323;
                        font-weight: bold;
                        margin: 0;
                        font-family: $font-title;
                        i {
                            color: $theme-primary-color;
                        }
                    }
                }
            }
        }
    }
    &.element-template--onderhoud, &.element-template--blog {
        .detail {
            .mod_detail_item {
                @media only screen and (max-width: 600px) {
                    display: block;
                }
                display: table;
                .mod_detail_item_body, .mod_detail_item_images {
                    display: table-cell;
                    vertical-align: top;
                    @media only screen and (max-width: 600px) {
                        display: block;
                    }
                }
                .mod_detail_item_body {
                    width: 60%;
                    box-sizing: border-box;
                    padding-right: $dark-margin;
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                    }
                    h1 {
                        display: table;
                        position: relative;
                        margin-top: $purple-margin;
                        margin-bottom: 60px;
                        &:after {
                            content: "";
                            width: 40%;
                            height: 5px;
                            position: absolute;
                            background: #6aab35;
                            bottom: -$pink-margin;
                            left: 0;
                        }
                    }
                }
                .mod_detail_item_images {
                    position: relative;
                    width: 40%;
                    max-width: 40vw;
                    box-sizing: border-box;
                    padding-left: $dark-margin;
                    @media only screen and (max-width: 600px) {
                        width: 100%;
                        padding-left: 0;
                        margin-top: 1em;
                    }
                    .pinterest {
                        top: 1em;
                        bottom: initial;
                    }
                    .primary_image {
                        display: block;
                        box-sizing: border-box;
                        width: 100%;
                        -webkit-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                        -moz-box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                        box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                        border: 15px solid #fff;
                        img {
                            display: block;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                    .mod_detail_item_images_thumbnails {
                        margin-top: $lightblue;
                        //display:table;
                        //width:100%;
                        //margin-top:$lightblue;
                        //.secondary_image {
                        //    display:table-cell;
                        //    img {
                        //        object-fit:cover;
                        //        height:100%;
                        //        display:block;
                        //    }
                        //}
                        @include basic-columns(
                            $selector: '.secondary_image',
                            $count: 4,
                            $dimensions: (25%, 25%, 25%, 25%),
                            $breakpoint: tablet-portrait,
                            $gutter: (.5em, 10%),
                            $gutter-bottom: 1em
                        );
                        .secondary_image {
                            box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.2);
                            img {
                                object-fit: cover;
                                display: block;
                                width: 100%;
                                height: 80px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/***********************************************************/
// Einde Detail modules
/***********************************************************/

/***********************************************************/
// Webshop overview module
/***********************************************************/
.mod-webshop-overview-v2 .mod_webshopcartv2_default {
    @include clearfix;
    .product-sidebar {
        float: left;
        width: 20%;
        @include phone-portrait {
            display: none;
            width: 100%;
        }
        &.collecties:before {
            content: "Collecties";
        }
        &:before {
            display: block;
            content: "Producten";
            background: #000;
            width: 100%;
            color: #fff;
            padding: $pink-margin $darkorange-margin;
            border-radius: 2px 2px 0px 0px;
            box-sizing: border-box;
            font-size: 20px;
            font-weight: bold;
        }

        .menu-item {
            display: block;
            border-left: 1px solid #e4e4e4;
            border-right: 1px solid #e4e4e4;
            border-bottom: 1px solid #e4e4e4;
            h6 {
                font-size: 17px;
                font-family: $font-text;
                font-weight: 600;
                margin: 0;
                position: relative;
                a {
                    display: block;
                    padding: $pink-margin $darkorange-margin;
                    background: #fff;
                    &:hover {
                        cursor: pointer;
                        background: #f1f1f1;
                        color: #232323;
                    }
                }
                i {
                    float: right;
                    transform: rotate(0deg);
                    transition: transform .2s;
                }
                &.active {
                    i {
                        transform: rotate(90deg);
                    }
                }
            }
            &.has-children {
                &.active {
                    background: #f1f1f1;
                }
                h6 {
                    color: $theme-primary-color;
                    display: block;
                    padding: $pink-margin $darkorange-margin;
                    &:hover {
                        cursor: pointer;
                        background: #f1f1f1;
                        a {
                            color: #232323;
                        }
                    }
                    a {
                        display: inline-block;
                        padding: 0;
                        background: transparent;
                    }
                    &:active {
                        color: #232323;
                    }
                }
            }
            ul {
                display: none;
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    font-size: 17px;
                    padding: 0 $darkorange-margin $pink-margin $darkorange-margin;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &.active {
                        a {
                            font-weight: 600;
                        }
                    }
                    a {
                        color: #232323;
                        display: block;
                        &:hover {
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
    .products {
        float: right;
        width: 80%;
        box-sizing: border-box;
        padding-left: $purple-margin;
        @media only screen and (max-width: 600px) {
            display: block;
            width: 100%;
            padding-left: 0;
        }
    }
    .categoriesOverviews {
        float: right;
        width: 80%;
        box-sizing: border-box;
        padding-left: $purple-margin;
        @media only screen and (max-width: 600px) {
            display: block;
            width: 100%;
            padding-left: 0;
            margin-top: 1em;
        }
        .breadcrumb {
            margin-bottom: $green-margin;

            .switch {
                display: table;
                margin: $green-margin auto 0 auto;

                a {
                    font-size: 1.2em;
                }
            }
        }
        .categories {
            @include basic-columns(
                $selector: '.category',
                $count: 3,
                $dimensions: (33.33333%, 33.33333%, 33.33333%),
                $breakpoint: tablet-portrait,
                $gutter: ($darkorange-margin, 10%),
                $gutter-bottom: $green-margin
            );
            .category {
                transform: scale(1, 1);
                transition: transform .3s;
                @include tablet-portrait {
                    display: block;
                }
                &:hover {
                    background: #f1f1f1;
                    transform: scale(1.05, 1.05);
                }
                .categoryImage {
                    overflow: hidden;
                    background: #fff;
                    height: 200px;
                    border-left: 1px solid #e4e4e4;
                    border-right: 1px solid #e4e4e4;
                    border-top: 1px solid #e4e4e4;
                    div {
                        display: block;
                        height: 100%;
                        img {
                            display: block;
                            width: auto;
                            max-width: 100%;
                            max-height: 100%;
                            /* height: 100%; */
                            margin: 0 auto;
                            /* min-width: 304px; */
                            /* min-height: 200px; */
                            //@include tablet-portrait {
                            //    width:100%;
                            //    height:auto;
                            //}
                            @media only screen and (max-width: 600px) {
                                height: 100%;
                            }
                        }
                    }
                }
                .productInfo {
                    background: #fff;
                    padding: $green-margin;
                    box-sizing: border-box;
                    font-family: $font-title;
                    font-weight: bold;
                    text-align: center;
                    border-left: 1px solid #e4e4e4;
                    border-right: 1px solid #e4e4e4;
                    border-bottom: 1px solid #e4e4e4;
                    color: #232323;
                    i {
                        color: $theme-primary-color;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .products {
        position: relative;
        @include basic-columns(
            $selector: '.product',
            $count: 3,
            $breakpoint: tablet-portrait,
            $gutter: ($darkorange-margin, 10%),
            $gutter-bottom: $green-margin
        );
        .product {
            background: #fff;
            border: 1px solid #e4e4e4;
            box-sizing: border-box;
            padding: $green-margin $lightblue;
            transform: scale(1, 1);
            transition: transform .3s;
            @include tablet-portrait {
                display: block;
            }
            &:hover {
                background: #f1f1f1;
                transform: scale(1.05, 1.05);
            }
            .productImage {
                display: table;
                height: 230px;
                overflow: hidden;
                div {
                    display: table-cell;
                    vertical-align: middle;
                    height: 100%;
                    img {
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        margin: 0 auto;
                        //min-width: 268px;
                        //min-height: 230px;
                    }
                }
                .thumbs {
                    display: table;
                    width: 100%;
                    a {
                        display: table-cell;
                        img {
                            object-fit: cover;
                            height: 100%;
                            display: block;
                        }
                    }
                }
            }
            .productInfo {
                box-sizing: border-box;
                position: relative;
                padding: 1em 10px 10px 0;
                .top-wrapper {
                    text-align: center;
                    span {
                        color: #232323;
                        font-family: $font-title;
                        i {
                            color: $theme-primary-color;
                            font-weight: bold;
                        }
                    }
                    .priceDiv {
                        margin-top: $pink-margin;
                        .price {
                            span {
                                color: $theme-primary-color;
                            }
                        }
                        p {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
    .mod_webshopoverview_product {
        float: right;
        width: 80%;
        box-sizing: border-box;
        padding-left: $purple-margin;
        @media only screen and (max-width: 600px) {
            display: block;
            width: 100%;
            padding-left: 0;
            margin-top: 1em;
        }
        .breadcrumb {
            display: block;
            width: 100%;
            margin-bottom: $green-margin;
        }
        .mod_webshopoverview_detail {
            display: block;
            width: 100%;
            background: #fff;
            box-sizing: border-box;
            border: 1px solid #e4e4e4;
            padding: $green-margin;
            .backlink {
                display: block;
                font-size: 0.842em;
                margin-bottom: $green-margin;
            }
            .detail_box {
                display: table;
                width: 100%;
                @media only screen and (max-width: 600px) {
                    display: block;
                }
                .productImage {
                    display: table-cell;
                    width: 40%;
                    box-sizing: border-box;
                    padding-right: $green-margin;
                    vertical-align: top;
                    @media only screen and (max-width: 600px) {
                        display: block;
                        width: 100%;
                        padding-right: 0;
                    }
                    .primary-image {
                        position: relative;
                        max-width: 426px;
                        margin-bottom: $lightblue;
                        a {
                            display: block;
                            img {
                                display: block;
                                object-fit: cover;
                                width: 100%;
                            }
                        }
                    }
                    .thumbs {
                        @include basic-columns(
                            $selector: '.thumb',
                            $count: 3,
                            $dimensions: (25%, 25%, 25%, 25%),
                            $gutter: ($lightblue, 10%),
                            $gutter-bottom: $green-margin
                        );
                        a {
                            height: 80px;
                            img {
                                object-fit: cover;
                                width: 100%;
                                display: block;
                            }
                        }
                    }
                }
                .product_overview {
                    display: table-cell;
                    width: 60%;
                    @media only screen and (max-width: 600px) {
                        display: block;
                        width: 100%;
                    }
                    .colors {
                        display: block;
                        margin-bottom: .5em;
                    }
                    .colors.alt {
                        display: none;
                    }
                    h2 {
                        margin: 0 0 $green-margin 0;
                    }
                    .price {
                        margin-bottom: $darkorange-margin;
                        div {
                            font-size: 1.579em;
                            color: $theme-primary-color;
                        }
                    }
                    .selectboxes {
                        margin-bottom: $darkorange-margin;
                        .select {
                            position: relative;
                            select {
                                background: none;
                            }
                            &:first-child {
                                margin-bottom: $darkpink;
                            }
                            &:after {
                                width: 10%;
                                top: 50%;
                                height: 100%;
                                transform: translateY(-50%);
                                content: "";
                                border-left: 1px solid #eee;
                                right: 0;
                                position: absolute;
                                background: url('../images/arrows.png');
                                background-repeat: no-repeat;
                                background-position: center center;
                                pointer-events: none;
                            }
                        }
                    }
                    .description {
                        b, p {
                            margin: 0;
                            font-size: 0.895em;
                        }
                        b {
                            margin-bottom: $lightblue;
                        }
                        p {
                            font-weight: 500;
                            &:last-of-type {
                                margin-bottom: $darkorange-margin;
                                border-bottom: 1px solid #e4e4e4;
                                padding-bottom: $darkorange-margin;
                            }
                        }
                    }
                    .columns.two {
                        @include basic-columns(
                            $selector: '.column',
                            $count: 2,
                            $dimensions: (35%, 65%),
                            $breakpoint: tablet-portrait,
                            $gutter: ($lightblue, 10%),
                            $gutter-bottom: $green-margin
                        );
                        .column {
                            &:last-child {
                                font-size: 0.895em;
                                span {
                                    display: block;
                                    margin-bottom: .5em;
                                    img {
                                        vertical-align: middle;
                                        width: 30px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .detail_description {
                padding-top: 2.105rem;
                border-top: 1px solid #e4e4e4;
                margin-top: 2.105rem;
                h3 {
                    margin-top: 0;
                }
                p {
                    font-size: 17px;
                    /*line-height:1.2;*/
                    margin: .5em 0;
                }
            }
            .detail_bottom {
                display: block;
                text-align: center;
                margin-top: $green-margin;
                .top {
                    padding-bottom: $green-margin;
                    border-bottom: 1px solid #e4e4e4;
                    margin-bottom: $green-margin;
                    font-size: 0.895em;
                }
                .bottom {
                    @include tablet-portrait {
                        display: none;
                    }
                    img {
                        max-width: 100%;
                    }
                }
                .bottomv {
                    display: none;
                    @include tablet-portrait {
                        display: block;
                    }
                    img {
                        max-width: 100%;
                    }
                }
            }
        }
        .detail_howto {
            display: block;
            width: 100%;
            background: #fff;
            box-sizing: border-box;
            border: 1px solid #e4e4e4;
            margin-top: 1em;
            .middle {
                .wrapper {
                    padding: 2.105rem;
                }

                img {
                    width: 100%;
                    height: auto;
                    display: block;
                    box-sizing: border-box;

                    &.normal {
                        display: block;
                    }

                    &.responsive {
                        display: none;
                    }
                }

                @media screen and (max-width: 600px) {
                    img {
                        &.normal {
                            display: none;
                        }

                        &.responsive {
                            display: block;
                        }
                    }
                }
            }
            .colors {
                display: none;
                &.alt {
                    display: block;
                    margin-top: .5em;
                }
            }
            h3 {
                margin-top: 0;
            }
            p {
                font-size: 17px;
                /*line-height:1.2;*/
                margin: .5em 0;
            }
            .column {
                @media only screen and (max-width: 600px) {
                    display: block;
                    width: 100%;
                }
            }
            .selectboxes {
                .select {
                    &:first-of-type {
                        margin-right: .5em;
                        width: calc(49.5% - .5em);
                        @media only screen and (max-width: 600px) {
                            display: block;
                            width: 100%;
                            margin: 0;
                            margin-bottom: .5em;
                        }
                    }
                    &:last-of-type {
                        margin-left: .5em;
                        width: calc(49.5% - .5em);
                        @media only screen and (max-width: 600px) {
                            display: block;
                            width: 100%;
                            margin: 0;
                            margin-bottom: .5em;
                        }
                    }
                }
            }
            .table-middle {
                display: table;
                width: 100%;
                background: #fbfbfb;
                .cell {
                    display: table-cell;
                    width: 33.333%;
                    //padding: 2.105rem;
                    box-sizing: border-box;
                    //border-top:1px solid #e4e4e4;
                    //border-bottom:1px solid #e4e4e4;
                    transition: background .3s ease-in-out;
                    transform: translateZ(0);

                    img {
                        transform: translateZ(0);
                        box-sizing: border-box;
                        width: 100%;
                        height: auto;
                        display: block;
                        transition: opacity .2s ease-in-out;
                    }

                    @media screen and (max-width: 600px) {
                        width: 100%;
                        display: block;
                    }
                    //&:nth-child(2) {
                    //    border-left:1px solid #e4e4e4;
                    //    border-right:1px solid #e4e4e4;
                    //}
                    &:hover {
                        background: darken(#fbfbfb, 5%);

                        img {
                            opacity: .85;
                        }
                    }
                    h5 {
                        margin-top: 0;
                    }
                    h4 {
                        font-size: 1.1em;
                        margin-top: 0;
                        margin-bottom: 1em;
                        color: #212121;
                        font-weight: 600;
                        position: relative;
                        display: inline-block;

                        span {
                            position: absolute;
                            display: inline-block;
                            background: {
                                position: center;
                                repeat: no-repeat;
                                size: contain;
                            }
                        ;
                            width: 1.85em;
                            height: 1.85em;
                            top: 0;
                            right: 0;
                            transform: translate(125%, -25%);

                            &.in-stock {
                                background-image: url('../images/in-stock.svg');
                            }

                            &.store {
                                background-image: url('../images/stores.svg');
                            }

                            &.questions {
                                background-image: url('../images/questions.svg');
                            }
                        }
                    }
                    p {
                        color: #212121;
                        min-height: 40px;
                    }
                    .read-more {
                        color: $theme-primary-color;
                    }
                }
            }
            .select {
                display: inline-block;
                width: 49.5%;
            }
            .columns {
                margin-top: 1em;
                &.two {
                    .column {
                        &:first-of-type {
                            span {
                                display: block;
                                margin-bottom: .5em;
                                img {
                                    vertical-align: middle;
                                    width: 30px;
                                }
                            }
                        }
                        &:last-of-type {
                            text-align: right;
                            @media only screen and (max-width: 600px) {
                                text-align: left;
                            }
                            .price {
                                display: inline-block;
                                margin-right: .5em;
                                @media only screen and (max-width: 600px) {
                                    margin-bottom: .5em;
                                }
                                h2 {
                                    color: $theme-primary-color;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .pagination {
        @include clearfix;
        display: table;
        width: 100%;
        .previous, .next {
            display: none;
        }
        .page-links {
            display: table;
            margin: 20px auto 0 auto;

            span {
                a {
                    min-width: 20px;
                    padding: .5em .6em;
                    background: $theme-primary-color;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    margin-right: 0.526em;
                    border-radius: 5px;
                    &:hover, &.active {
                        background: #232323;
                    }
                }
                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    //.pagination {
    //    display: table;
    //    box-sizing: border-box;
    //    margin:0 auto;
    //    padding-top:2em;
    //    .previous, .next {
    //        &.disabled {
    //            min-width: 20px;
    //            padding: .5em .6em;
    //            background: $theme-primary-color;
    //            display: table-cell;
    //            color: #fff;
    //            text-align: center;
    //            margin-right: 0.526em;
    //            border-radius: 5px;
    //            opacity:.5;
    //        }
    //        a {
    //            min-width: 20px;
    //            padding: .5em .6em;
    //            background: $theme-primary-color;
    //            display: table-cell;
    //            color: #fff;
    //            text-align: center;
    //            margin-right: 0.526em;
    //            border-radius: 5px;
    //            color:#fff;
    //            &:hover {
    //                background: #232323;
    //            }
    //        }
    //    }
    //    .page-links {
    //        display:table-cell;
    //        margin:0 .5em;
    //        span {
    //            display:table-cell;
    //            &.separator {
    //                min-width: 20px;
    //                padding: .5em .6em;
    //                display: table-cell;
    //                text-align: center;
    //                margin-right: 0.526em;
    //                border-radius: 5px;
    //                opacity:.5;
    //            }
    //            &:nth-child(2) {
    //                a {
    //                    margin:0;
    //                }
    //            }
    //            a {
    //                min-width: 20px;
    //                padding: .5em .6em;
    //                background: $theme-primary-color;
    //                display: inline-block;
    //                color: #fff;
    //                text-align: center;
    //                margin-left: 0.263em;
    //                margin-right: 0.263em;
    //                border-radius: 5px;
    //                &.active {
    //                    background: #232323;
    //                }
    //                &:hover {
    //                    background: #232323;
    //                }
    //            }
    //        }
    //    }
    //}
}

/***********************************************************/
// Einde Webshop overview module
/***********************************************************/

/***********************************************************/
// Euphoria Menu
/***********************************************************/
.euphoria-menu {
    @include responsive-menu();
    @include responsive-menu-content();
    background: rgba($theme-primary-color, .9);

    a {
        &:first-child {
            padding-right: 3em;
        }
        color: #fff;
    }
}

.euphoria-menu-button {
    @include responsive-menu-button();
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);

}

.euphoria-menu-overlay {
    @include responsive-menu-overlay($color: rgba(#fff, .8));
}

/***********************************************************/
// Einde Euphoria Menu
/***********************************************************/
/***********************************************************/
// Home specifieke aanpassingen
/***********************************************************/
.layout-home {
    .phone-sidebar-button {
        display: none;
    }
    .overview {
        .pagination {
            display: none;
        }
    }
    .element-template--default {
        .product-sidebar {
            display: none;
        }
        .breadcrumb {
            display: none;
        }
        .products:nth-child( 1n ) {
            position: relative;
            width: 100%;
            margin: 0;
            padding: 0;
            margin-bottom: $blue-margin;

            @include basic-columns(
                $selector: '.product',
                $count: 5,
                $breakpoint: tablet-portrait,
                $gutter: ($darkorange-margin, 10%),
                $gutter-bottom: $green-margin
            );
            @media only screen and (max-width: 769px) {
                @include basic-columns(
                    $selector: '.product',
                    $count: 3,
                    $breakpoint: tablet-portrait,
                    $gutter: ($darkorange-margin, 10%),
                    $gutter-bottom: $green-margin
                );
            }
            .product:nth-child( 1n ) {
                box-sizing: border-box;
                background: #fff;
                border: 1px solid #e4e4e4;
                box-sizing: border-box;
                padding: $green-margin $lightblue;
                transform: scale(1, 1);
                transition: transform .3s;
                clear: none !important;
                @include tablet-portrait {
                    display: block;
                }

                &:hover {
                    background: #f1f1f1;
                    transform: scale(1.05, 1.05);
                }
                @media only screen and (max-width: 769px) {
                    &:nth-child(4), &:nth-child(5) {
                        display: none;
                    }
                }
                .productImage {
                    //display:block;
                    height: 230px;
                    overflow: hidden;
                    @include tablet-portrait {
                        display: table;
                        margin: 0 auto;
                    }
                    div {
                        //display:block;
                        height: 100%;
                        img {
                            //display:block;
                            //width: auto;
                            //height:100%;
                            //min-width: 189px;
                            //min-height: 230px;
                            @include tablet-portrait {
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                .productInfo {
                    box-sizing: border-box;
                    padding: 0 10px;
                    .top-wrapper {
                        text-align: center;
                        min-height: 133px;
                        span {
                            color: #232323;
                            font-family: $font-title;
                            i {
                                color: $theme-primary-color;
                                font-weight: bold;
                            }
                        }
                        .priceDiv {
                            margin-top: $pink-margin;
                            .price {
                                span {
                                    color: $theme-primary-color;
                                }
                            }
                            p {
                                margin: 0;
                            }
                        }
                    }
                }
            }

            .topTitle {
                display: none;
            }
            .productImage {
                margin-bottom: 1em;
            }
        }
    }
}

/***********************************************************/
// Einde Home specifieke aanpassingen
/***********************************************************/

/***********************************************************/
// Default pagina met lijntjes
/***********************************************************/
.layout-defaultmetlijntjes {
    .nopadding {
        .columns {
            &.twoo {
                .column {
                    padding: $purple-margin 0;
                }
            }
        }
    }
    .columns {
        &.twoo {

            .column {
                &:first-child {
                    border-right: 1px solid #654122;
                    h3 {
                        position: relative;
                        &:after {
                            position: absolute;
                            right: -2em;
                            top: 50%;
                            transform: translateY(-50%);
                            content: "";
                            width: 15px;
                            height: 15px;
                            background: #654122;
                            border-radius: 100px;
                            @include phone-portrait {
                                display: none;
                            }
                        }
                    }
                }
                &:last-child {
                    h3 {
                        position: relative;
                        &:before {
                            position: absolute;
                            left: -2em;
                            top: 50%;
                            transform: translateY(-50%);
                            content: "";
                            width: 15px;
                            height: 15px;
                            background: #654122;
                            border-radius: 100px;
                        }
                    }
                }
            }
        }
    }
}

/***********************************************************/
// Einde Default pagina met lijntjes
/***********************************************************/
.module_sitemap {
    h3 {
        display: none;
    }
}

/***********************************************************/
// Webshop Cart
/***********************************************************/
.mod-webshop-cart-v2 {
    @include clearfix;

    h4, h5 {
        text-transform: none;
        font-size: 1.5em;

        a {
            font-size: .66667em;
            font-weight: normal;
            margin-top: .24em;
        }
    }

    table {
        border-collapse: collapse;
        table-layout: fixed;
        margin: .5em 0 1em;
        width: 100%;
    }

    .webshopcartv2-continue-shopping:before {
        content: '⬅';
        display: inline-block;
        margin: 0 .8em 0 .2em;
        position: relative;
        top: 2px;
        vertical-align: middle;
    }

    .cart-overview table {
        .description {
            width: 54%;
        }
        .pieceprice {
            width: 12%;
        }
        .quantity {
            width: 16%;
            input {
                text-align: right;
                max-width: 100px;
            }
        }
        .price {
            width: 12%;
        }
        .price, .pieceprice {
            white-space: nowrap;
        }
        .remove {
            width: 6%;

            .button {
                background: transparent !important;
                padding: .3em .5em;

                &:before {
                    color: $theme-primary-color;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f00d";
                    font-size: 1.3em;
                    vertical-align: middle;
                }

                img {
                    display: none;
                }
                &:hover {
                    background: $theme-primary-color !important;
                    &:before {
                        color: #fff !important;
                    }
                }
            }
        }

        td, th {
            box-sizing: border-box;
            padding-right: 20px;
            padding-top: 1em;
            padding-bottom: 1em;
            border-bottom: #efefef 1px solid;

        }
        tbody {
            tr {
                &:nth-child(even) {
                }
                &:nth-child(odd) {
                    background: #efefef;
                }
                td {
                    &:first-of-type {
                        padding-left: 1em;
                    }
                }
            }
        }
        .remove {
            padding: 0;
        }

        tr.empty td {
            padding: 2em 18px;
            text-align: center;
        }

        &.empty tfoot {
            display: none;
        }

        @media only screen and (max-width: 640px) {
            font-size: .8em;
            thead {
                display: none;
            }

            .description {
                width: 40%;
            }
            .pieceprice, .price {
                width: 15%;
            }
        }
    }

    .steps, .form-wrapper {
        float: left;
    }

    .steps {
        margin-right: 5%;
        width: 20%;

        strong {
            display: none;
        }

        .step {
            margin: 0 0 .8em;

            a, .caption {
                display: block;
                text-decoration: none;
            }

            &.active {
                color: $theme-primary-color;
            }

            &.completed a {
                color: #619431;
            }

            .caption:before {
                content: "\f007";
                font: normal normal normal .9em/1 FontAwesome;
                display: inline-block;
                //font-family: SSGizmo;
                margin: 0 .5em 0 0;
                position: relative;
                top: 1px;
                vertical-align: middle;
            }

            &.step-2 .caption:before {
                content: "\f0d1";
            }
            &.step-3 .caption:before {
                content: "\f03a";
            }
            &.step-4 .caption:before {
                content: "\f09d";
            }
            &.step-5 .caption:before {
                content: '✓';
            }
        }
    }

    .form-wrapper {
        width: 75%;

    }

    @media only screen and (max-width: 640px) {
        .webshopcartv2-continue-shopping {
            overflow: hidden;
            white-space: nowrap;
            width: 40px;
        }

        .steps, .form-wrapper {
            float: none;
            width: auto;

            > h4:first-child, > h5:first-child {
                display: none;
            }
        }

        .cart-overview table {
            margin-left: -1em;
            margin-right: -1em;
            width: "calc( 100% + 1em * 2 )";
        }

        .steps {
            @include clearfix;
            border-bottom: 1px solid #ededed;
            margin: -1.2em 0 1em;
            text-align: center;

            .step {
                float: left;
                margin: 0;
                width: 15%;

                .caption {
                    font-size: 0;
                    padding: 10px;
                    white-space: nowrap;

                    &:before {
                        font-size: 19px;
                        margin: 0;
                    }
                }

                &.active {
                    width: 40%;
                    .caption {
                        font-size: inherit;

                        &:before {
                            margin: 0 .5em 0 0;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 480px) {
        .steps .step {
            width: 13%;

            &.active {
                width: 48%;
            }
        }
    }

    .webshop-step-two {
        .radiobutton {
            margin: .6em 0;
        }
    }

    .webshop-step-three {
        .section {
            border-bottom: 1px solid #ededed;
            margin: 0 0 2.4em;
            padding: 0 0 1.4em;

        }

        table {
            .quantity {
                width: 10%;
            }
            .product-overview {
                width: 50%;
            }
            .pieceprice, .price {
                width: 20%;
                white-space: nowrap;
            }
        }

        .container {
            @include clearfix;

            label, span {
                display: block;
                float: left;
                width: 24%;
            }

            label {
                font-weight: 600;
                margin: 0 4% 0 0;
            }

            span {
                width: 70%;
            }
        }

        p {
            margin: 0 0 .8em;
        }

        @media only screen and (max-width: 640px) {
            table {
                .label {
                    text-indent: -9999px;
                }
            }
        }
    }
    #checkout_overview_order {
        td, th {
            box-sizing: border-box;
            padding-right: 20px;
            padding-top: 1em;
            padding-bottom: 1em;
            border-bottom: #efefef 1px solid;

        }
        tbody {
            tr {
                &:nth-child(even) {
                }
                &:nth-child(odd) {
                    background: #efefef;
                }
                td {
                    &:first-of-type {
                        padding-left: 1em;
                    }
                }
            }
        }
    }
}

.mod-webshop-cart-v2 .webshop-step-one form, .mod-user-login .mod_webshopcartv2_default form {
    @include clearfix;

    fieldset {
        float: left;
        margin: 0 0 2em 0;
        width: 47%;
        @include clearfix;
    }

    .general-info .inputtext {
        float: left;
        margin-right: 4%;
        width: 30%;

        &.name {
            margin-right: 0;
            width: 66%;
        }
    }

    .order-type {
        float: right;
        margin-right: 0;

        .radiogroup > label {
            display: none;
        }
    }

    .general-info, .address, .contact-details {
        margin-right: 6%;
    }

    .address, .contact-details {
        clear: both;
    }

    #aanhef {
        margin: 0 0 .8em;
        label {
            margin-right: 1em;
        }
        .radiobutton {
            display: inline-block;
        }

        > label {
            display: none;
        }
    }

    .address .inputtext, .alternative-address .inputtext {
        float: left;
    }

    .address_postal_street, .address_delivery_street {
        margin-right: 4%;
        width: 62%;
    }

    .address_postal_number, .address_delivery_number {
        width: 34%;
    }

    .address_postal_postalcode, .address_delivery_postalcode {
        clear: both;
        margin-right: 4%;
        width: 34%;
    }

    .address_postal_city, .address_delivery_city {
        width: 62%;
    }

    button {
        clear: both;
        display: block;
    }

    @media only screen and (max-width: 640px) {
        fieldset {
            float: none !important;
            margin: 0 0 1.4em !important;
            width: auto;

            select {
                max-width: 200px;
            }
        }
    }
}

.secondary_menu {
    display: inline-block;
}

.cart {
    position: absolute;
    right: 1em;
    top: 50%;
    transform: TranslateY(-50%);
    @include tablet-portrait {
        margin-right: 4em;
    }
    #WebShopCartTop {
        background: #000;
        .cartname {
            float: left;
            font-size: .8em;
            color: #121212;

            @include phone-small-portrait {
                display: none;
            }
        }
        .total_price {
            float: right;
            clear: left;
            font-size: .85em;
        }
        .cartimage {
            float: right;
            margin-left: .5em;
            position: relative;
            .amount {
                position: absolute;
                top: 0;
                font-size: .8em;
                right: -.7em;
                text-align: center;
                min-width: 13px;
                background: #6aab35;
                color: #fff;
                padding: .2em .5em;
                border-radius: 100%;
            }
        }
    }
}

/***********************************************************/
// Begin pagina layout met sidebar
/***********************************************************/
.layout-sidebar {
    .mod_webshopcartv2_default {
        .product-sidebar {
            width: 100%;
        }

        .categoriesOverviews, .products {
            float: none;
            width: 100%;
            padding: 0;
        }
    }
}

/***********************************************************/
// Einde pagina layout met sidebar
/***********************************************************/
.horizontal-switch {
    position: relative;
    display: table;
    margin: 0 auto;
    margin-bottom: 1em;
    a {
        margin-right: .5em;
        @include phone-portrait {
            display: block;
            margin-top: .5em;
        }
    }
}

.phone-sidebar-button {
    display: none;
    @include phone-portrait {
        display: block;
    }
    a {
        @include clearfix;
        display: block;
        background: #eee;
        position: relative;
        padding: 1.316rem 1.579rem;
        i {
            float: right;
            transform: rotate(0deg);
            transition: transform .2s;
        }
        &.active {
            i {
                transform: rotate(90deg);
            }
        }
    }
}

.cart_add {
    position: fixed;
    bottom: -11em;
    right: 2em;
    display: table;
    background: #fff;
    border: 1px solid #efefef;
    padding: 1em;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    transition: bottom .2s;
    &.active {
        bottom: 3em;
    }
    .image {
        position: relative;
        display: table;
        margin: 0 auto;
        img {
            display: block;
        }
        .amount {
            position: absolute;
            top: 0;
            font-size: .8em;
            right: -.7em;
            background: #6aab35;
            color: #fff;
            padding: .2em .5em;
            border-radius: 100%;
            width: 10px;
            text-align: center;
        }
    }
}

select {
    &:after {
        width: 10%;
        top: 50%;
        height: 100%;
        transform: translateY(-50%);
        content: "";
        border-left: 1px solid #eee;
        right: 0;
        position: absolute;
        background: url('../images/arrows.png');
        background-repeat: no-repeat;
        background-position: center center;
        pointer-events: none;
    }
}

.button.order.add-to-cart {
    &:after {
        content: "+ toegevoegd aan winkelmand";
        position: Absolute;
        width: 200px;
        font-size: .7em;
        top: calc(100% + 10px);
        left: 0;
        color: #fff;
        transition: color .2s;
    }
    &.clicked {
        &:after {
            color: #000;
        }
    }
}

.bar {
    @include clearfix;
    .left {
        float: left;
        width: 20%;
        @include phone-portrait {
            display: block;
            width: 100%;
        }
    }
    .right {
        float: right;
        width: 80%;
        padding-left: 3.158rem;
        box-sizing: border-box;
        @include phone-portrait {
            display: block;
            width: 100%;
            padding: 0;
        }
    }
}

#radioelements_type_Klant-organisatie, #radioelements_other_delivery_address_yes {
    display: none;
}

section.aankondiging {

    padding-bottom: 2em;

    .columns {
        border-radius: 3px;
        display: table;
        table-layout: fixed;
        box-sizing: border-box;
        border: 1px solid #e4e4e4;
        border-left: 4px solid $theme-primary-color;

        .column {
            box-sizing: border-box;
            position: relative;
            display: table-cell;
            vertical-align: middle;
            padding: .5em 1.5em;
            &:nth-child( 1 ) {
                background: #fbfbfb;
                border-right: 1px solid #e4e4e4;
                width: 22%;
                h6 {
                    display: block;
                    font-size: 1em; /* 18/18 */
                    background: {
                        image: url('../images/megaphone.svg');
                        position: center left;
                        repeat: no-repeat;
                        size: contain;
                    }
                    width: 54px;
                    height: 42px;
                    padding-left: 76px;

                    span {
                        position: relative;
                        top: .5em;
                    }
                }
            }

            &:nth-child( 2 ) {
                background: #fff;
                width: 78%;

            }
        }

        @include tablet-portrait {
            .column {
                &:nth-child( 1 ) {
                    width: 16%;

                    h6 {
                        padding-left: 0;

                        span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.metfoto {
        .column {
            @media only screen and (max-width: 600px) {
                display: block;
                float: none;
                width: 100% !important;;
                margin: 0;
            }
            .mod-images {
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    img {
                        max-width: 100%;
                        min-height: 100%;
                        @media only screen and (max-width: 600px) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

section.blackbar {
    background: #000;
    color: #fff;
    padding: 1em;
    margin-top: calc(-1.6em + 30px);
    margin-bottom: calc(-2.368rem + 25px);
    h1, h2, h3, h4, h5, h6, p {
        color: #fff;
        margin: 0;
    }
}

form[enctype="multipart/form-data"] {
    display: inline-block;
}

span.let-op {
    display: inline-block;
    font-size: .85em;
    margin-left: .3em;

    /*&:before {
        content: '';
        background:  {
            image: url('../images/ideal.png');
            repeat: no-repeat;
            size: contain;
            position: center;
        };
        width: 23px;
        height: 23px;
        display:  inline-block;
        transform: translateY( 5px);
        margin-right: .3em;
    }*/
}

.mod-detail {
    &.element-template--lightbox-tiles,
    &.element-template--lightbox-rows {
        .items {
            .item {
                box-sizing: border-box;
                position: relative;
                overflow: hidden;
                background: #fff;
                border: 1px solid #e3e3e3;
                border-radius: 3px;

                .image {
                    box-sizing: border-box;
                    position: relative;
                    overflow: hidden;
                    .pinterest {
                        top: 1em;;
                        bottom: initial;
                        left: 1em;
                        right: initial;
                    }
                    img {
                        width: 100%;
                        height: auto;
                        display: block;
                    }

                    .counter {
                        box-sizing: border-box;
                        display: inline-block;
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        color: #fff;

                        .image-count {
                            box-sizing: border-box;
                            position: relative;
                            line-height: 0;
                            display: block;
                            padding: .8em;
                            border-radius: 3px;

                            &:before {
                                box-sizing: border-box;
                                content: '';
                                display: inline-block;
                                background: {
                                    image: url('../images/photo.svg');
                                    repeat: no-repeat;
                                    size: 25px 20px;
                                    position: center;
                                    color: rgba($theme-primary-color, .8);
                                }
                            ;
                                width: 45px;
                                height: 40px;
                                margin-right: .3em;
                                padding: .3em;
                                border-radius: 3px;
                            }
                        }

                        .number {
                            position: relative;
                            top: -12px;
                        }
                    }
                }

                .text {
                    box-sizing: border-box;
                    position: relative;
                    padding: 1em 1.75em;
                }
            }
        }
    }
    &.element-template--lightbox-rows {
        .items {
            .item {
                margin-bottom: 2.4em;

                &:last-child {
                    margin-bottom: 0;
                }

                @include basic-columns(
                    $count: 2,
                    $selector: '.part',
                    $dimensions: (40%, 60%),
                    $gutter: (0, 0),
                    $gutter-bottom: 0,
                    $breakpoint: phone-landscape
                );

                .text {
                    padding: 2.75em 2.5em;

                    @include phone-landscape {
                        padding: 1.75em 1.5em;
                    }

                    h2 {
                        margin-bottom: .6em;
                    }

                    .inner-text {
                        .controls {
                            .image-count {
                                position: relative;
                                margin-right: .5em;
                                font-weight: bold;
                                font-size: .8em;
                                text-transform: uppercase;

                                &:before {
                                    content: '';
                                    display: inline-block;
                                    box-sizing: border-box;
                                    background: {
                                        image: url('../images/photo.svg');
                                        repeat: no-repeat;
                                        size: 25px 20px;
                                        position: center;
                                    }
                                ;
                                    width: 25px;
                                    height: 20px;
                                    margin-right: .2em;
                                    position: relative;
                                    top: 2px;
                                }
                            }
                        }
                    }
                }

                @include phone-landscape {
                    .part:nth-child( 1n ) {
                        margin: 0;
                    }
                }
            }
        }
    }
    &.element-template--lightbox-tiles {
        .items {
            @include basic-columns(
                $count: 3,
                $selector: '.item',
                $gutter: (2.4em, 3.846%),
                $gutter-bottom: 2.4em,
                $breakpoint: phone-landscape
            );
            .image {
                height: 300px;
                img {
                    min-height: 100%;
                }
                .counter {
                    top: 0;
                    bottom: inherit;
                }
                h3 {
                    margin: 0;
                    position: absolute;
                    background: rgba(00, 00, 00, .5);
                    bottom: 0;
                    color: #fff;
                    width: 100%;
                    padding: .5em 1em;
                    box-sizing: border-box;
                }
            }
        }
    }
}

section.conversion-form {
    padding-top: 0;
    padding-bottom: 0;
    border-top: 2px solid #e4e4e4;
    border-bottom: 0
}

section.conversion-form .columns.contact {
    margin-bottom: 0
}

section.conversion-form .columns.contact:after {
    content: "";
    display: table;
    clear: both
}

section.conversion-form .columns.contact > .column {
    box-sizing: border-box;
    float: left;
    min-height: 1em
}

section.conversion-form .columns.contact > .column:nth-child(1n) {
    margin: 0;
    margin-right: 0
}

section.conversion-form .columns.contact > .column:nth-child(2n+1) {
    width: 63%
}

section.conversion-form .columns.contact > .column:nth-last-child(1) {
    margin-bottom: 0
}

section.conversion-form .columns.contact > .column:nth-child(2n+2) {
    width: 37%
}

section.conversion-form .columns.contact > .column:nth-last-child(2) {
    margin-bottom: 0
}

section.conversion-form .columns.contact > .column:nth-child(2n+1) {
    clear: both
}

section.conversion-form .columns.contact > .column:nth-child(2n+2) {
    margin-right: 0
}

@media only screen and (max-width: 768px) {
    section.conversion-form .columns.contact > .column {
        float: none
    }
    section.conversion-form .columns.contact > .column:nth-child(1n) {
        clear: none;
        margin: 0 0 2em;
        width: auto
    }
    section.conversion-form .columns.contact > .column:last-child {
        margin: 0
    }
}

section.conversion-form .column {
    box-sizing: border-box;
    position: relative;
    margin-bottom: 0
}

section.conversion-form .column .wrapper {
    box-sizing: border-box;
    max-width: calc(100% - ((100vw - 1266px) / 2));
    width: 100%;
    padding-top: 4.444rem;
    padding-bottom: 5.556rem
}

section.conversion-form .column:first-child {
    background: #f2f2f2;
    border-right: 2px solid #e4e4e4
}

section.conversion-form .column:first-child .wrapper {
    padding-right: 5.556rem;
    margin-left: calc((100vw - 1266px) / 2);
    padding-bottom: 6em;
}

section.conversion-form .column:first-child .mod-contact-form {
    margin-top: 2.222rem
}

section.conversion-form .column:last-child {
    padding-left: 3.333rem
}

section.conversion-form .column table td {
    box-sizing: border-box;
    padding: .18em .18em .3em 0
}

@media only screen and (max-width: 1280px) {
    section.conversion-form .column:nth-child(1n) {
        width: 100%;
        padding: 0
    }
    section.conversion-form .column:nth-child(1n) .wrapper:nth-child(1n) {
        max-width: none;
        margin-left: 0;
        margin-right: 0;
        padding: 3.333rem
    }
}

@media only screen and (max-width: 768px) {
    section.conversion-form .column:nth-child(1n) {
        width: 100%;
        padding: 0;
        margin-bottom: 0 !important
    }
    section.conversion-form .column:nth-child(1n) .wrapper:nth-child(1n) {
        padding: 3.333rem 1.667rem
    }
}

section.conversion-form .column:first-of-type .wrapper {
    @include clearfix;
}

section.conversion-form form[enctype="multipart/form-data"] {
    display: block;
}

section.conversion-form .column:first-of-type .wrapper button {
    float: right;
}

section.conversion-form .column:first-of-type .wrapper .mod_contactform_required {
    float: left;
}

.koopzondagen {
    position: fixed;
    bottom: 0;
    right: 1em;
    z-index: 1;
    @media only screen and (max-width: 600px) {
        position: Relative;
        bottom: inherit;
        right: inherit;
        display: block;
        width: 100%;
    }
    .knopblok {
        display: block;
        @include clearfix;
        cursor: pointer;
        .knop {
            display: table;
            color: #fff;
            font-size: 16px;
            font-weight: bold;
            padding: .5em 1em;
            float: right;
            cursor: pointer;
            background: $theme-primary-color;
            @media only screen and (max-width: 600px) {
                float: none;
                display: block;
                width: 100%;
                box-sizing: border-box;
            }
            &:after {
                background: url('../images/arrow.svg');
                content: "";
                height: 20px;
                width: 20px;
                vertical-align: middle;
                margin-left: .5em;
                display: inline-block;
                transform: rotate(180deg);
                transition: transform .2s ease-in-out;
            }
        }
    }
    .text {
        background: #fff;
        width: 271px;
        padding: 1.5em;
        border: 1px solid #e4e4e4;
        font-size: 17px;
        display: none;
        @media only screen and (max-width: 600px) {
            float: none;
            display: block;
            width: 100%;
            box-sizing: border-box;
        }
        a {
            &:after {
                font: normal normal normal 13px/1 FontAwesome;
                content: "\f054";
                font-weight: normal !important;
                margin-left: .5em;
            }
        }
        p {
            margin: 0;
            &:first-of-type {
                b, strong {
                    margin-top: 0;
                }
            }
            strong, b {
                margin-bottom: .25em;
                display: block;
                margin-top: 1em;
            }
        }
    }
    &.active {
        .knop {
            &:after {
                transform: rotate(0deg);
            }
        }
    }
}

.layout-webshop {
    .bar {
        .right {
            .mod-contact-form {
                display: block;
                width: 100%;
                background: #fff;
                box-sizing: border-box;
                border: 1px solid #e4e4e4;
                margin-top: 1em;
                padding: 2.105rem;
                button {
                    float: right;
                    @media only screen and (max-width: 600px) {
                        float: none;
                        margin: 0;
                    }
                }
                .some-fields-required {
                    float: left;
                    margin: 0;
                    margin-top: -2em;
                    @media only screen and (max-width: 600px) {
                        display: block;
                        float: none;
                        width: 100%;
                        margin: 0;
                    }
                }
                h3 {
                    margin: 0;
                    &:nth-of-type(3) {
                        margin-bottom: 1em;
                    }
                }
                form {
                    width: 70%;
                    @media only screen and (max-width: 600px) {
                        display: block;
                        float: none;
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
    }
}

.colors {
    display: block;
}

#inline {
    display: none;
    padding: 0 1em;
    h3 {
        margin: 0;
        &:first-of-type {
            font-size: 30px;
            color: $theme-primary-color;
            margin-bottom: .4em;
        }
        &:nth-of-type(2) {
            font-size: 20px;
            margin-bottom: .5em;
        }
    }
    h2 {
        position: relative;
        font-size: 1.875em;
        font-weight: normal;
        &:after {
            content: '';
            display: inline-block;
            position: absolute;
            width: 110px;
            height: 2px;
            bottom: -10px;
            left: 0;
            margin: 0 auto;
            background: #ec6a09;
        }
    }
    .formuliertje {
        display: inline-block;
        width: 100%;
        margin-top: 1em;
        .form {
            display: block;
        }
        form {
            display: block;
            width: 100%;
            button {
                float: right;
            }
            .checkboxbutton {
                label {
                    input {
                        margin-right: .5em;
                    }
                }
            }
        }
        @media screen and (max-width: 600px) {
            width: 100%;
            display: block;
        }
        button {
            @include button;
        }
    }
}

#contact {
    form {
        min-width: 70%;
    }
}

.pinterest {
    position: absolute;
    right: 1em;
    bottom: 0;
    opacity: .5;
    &:hover {
        opacity: 1;
    }
}

.folder-info {
    clear: both;
    margin-top: 3em;
}
